<template>
	<div>
		<menuBar />
		<div class="mt-15 pa-10">
			<h1>Hi There !</h1>
			<v-row class="text-justify">
				<v-col class="col-4">
					<v-img :src="$root.publicPath + `image/blog1.jpg`" />
				</v-col>
				<v-col>
					<p>
						<b>What Are the Benefits (and Risks) of Kratom? Scientists Have Some Answers</b>
					</p>
					<p>
						Over the past few years, kratom exploded into public consciousness in the United States. The herbal drug, which is legal in most of the country, has attracted a solid mix of positive and negative attention. People with chronic pain, opioid use disorder, and other health issues have sought out kratom as a natural alternative to prescription opioids — or illicit fentanyl and heroin. Meanwhile, federal regulators have tried to restrict access to the substance. The Food and Drug Administration has argued that another opioid on the market, especially a poorly understood one that’s available to purchase without a prescription, could contribute to the ongoing opioid overdose crisis that claims tens of thousands of lives each year.
					</p>
					<p>
						Amid this debate, it’s estimated that millions of people in the US use kratom regularly, and there’s little evidence to suggest that it’s is contributing in to the number of US overdose deaths. In 2017, there were 47,600 fatal overdoses in the US that involved opioids. In 18 months from July 2016 to December 2017, by comparison, only 152 overdose deaths involved kratom. Out of those 152, only 91 are suspected to have been caused by kratom, and experts have called those numbers “questionable.”
					</p>
					<p>
						Amid the debate, the main thing that experts can agree on is that more research is needed to determine the safety profile of this substance.
					</p>
					<p>
						So while there may be harms associated with kratom, there also seem to be benefits associated with it. And for those who are interested in using it, we’ve broken down the potential risks and benefits for you.
					</p>
					<p>
						Let’s briefly start with the drug’s origins.
					</p>
					<p>
						<b>TRADITIONAL USES AND BENEFITS OF KRATOM</b>
					</p>
					<p>
						Kratom, a tropical evergreen tree (Mitragyna speciosa), is native to several countries in Southeast Asia, including Thailand, Malaysia, and Indonesia, Papua New Guinea, and Myanmar. In these places, it’s been used for hundreds of years as a mild stimulant, a pain reliever, and as a way to wean off of opioids.
					</p>
					<p>
						For manual laborers and farmers, a tea brewed from kratom leaves can help fight the fatigue and pain associated with physical labor. And for people who want to stop using opium or other opioid drugs, kratom has been a way to transition from these addictive substances.
					</p>
					<p>
						<b>CAN KRATOM HELP TREAT OPIOID USE DISORDER?</b>
					</p>
					<p>
						As with any natural remedy, there’s no shortage of anecdotal reports on online forums of people who say they quit using heroin or prescription opioids by taking kratom capsules or drinking kratom tea. Inverse has reported on several such stories in the past.
					</p>
					<p>
						But what does the science say?
					</p>
					<p>
						In a paper published online on May 16, 2019 in the International Journal of Drug Policy, a team of experienced kratom researchers sought to answer some of the biggest questions about kratom, including this one.
					</p>
					<p>
						In short, they saw mixed results. Anecdotal reports suggest that kratom is an effective tool for reducing opioid dependence, but “kratom has not been evaluated in the types of multi-center, controlled clinical trials that are required by regulatory authorities, such as the US FDA, to conclude that a drug is safe and effective for the treatment of [opioid use disorder] or other indications.”
					</p>
					<p>
						Despite the lack of large-scale trials, they cite several small trials that support kratom as an effective therapy for opioid use disorder. For instance, a small 2009 study of Malaysian men showed that regular use of kratom helped reduce withdrawal symptoms and reduce their use of other drugs.
					</p>
					<p>
						<b>CAN KRATOM HELP TREAT PAIN?</b>
					</p>
					<p>
						As the authors note, several factors have led chronic pain patients to seek alternative to conventional pain management. One of those factors is the US Centers for Disease Control and Prevention’s guidelines for opioid prescribing, which urge doctors to limit long-term opioid prescriptions. The effects of this policy, as the CDC acknowledged, have hurt patients who lost access to the opioid prescriptions that help them make it through their day.
					</p>
					<p>
						Anecdotal reports, as told to Inverse, also suggest that some patients who have found the side effects of conventional opioids unacceptable turned to kratom.
					</p>
					<p>
						In the new kratom study in the Internationa Journal of Drug Policy, the researchers conclude that the evidence for kratom as a pain relieving drug is similar to the evidence for it as an opioid replacement: mixed.
					</p>
					<p>
						“The situation is analogous with respect to the management of pain, where kratom has not been approved as an analgesic medicine, but relief of pain is among the more commonly reported uses,” they write.
					</p>
					<p>
						As the FDA has warned, kratom has not been approved through the accepted medical pathways as a drug to be used for this purpose.
					</p>
					<p>
						“Nonetheless, the results of observational studies of kratom users in the US converge with case reports and descriptions of traditional kratom use in Southeast Asia to suggest that kratom does have utility as a substitute for potentially more dangerous classical opioids in treating pain and [opioid use disorder] and should be studied in well-controlled clinical trials for such indications,” the paper’s authors write.
					</p>
					<p>
						<b>WHAT ARE THE RISKS OF TAKING KRATOM?</b>
					</p>
					<p>
						As with any drug, there are risks.
					</p>
					<p>
						One of the main risks of kratom is physical and psychological withdrawal. After using kratom regularly for long periods of time, it’s common for people to feel anxious, depressed, or physically uncomfortable when they stop taking it. Many users liken the symptoms to those of classic opioid withdrawal, though not usually as severe.
					</p>
					<p>
						Kratom overdoses have gotten a lot of press, but most kratom overdose deaths usually involved large amounts of kratom taken with other drugs. As with any substance, knowing one’s limits and moderating to establish tolerance is key.
					</p>
					<p>
						Unfortunately, due to the lack of research on kratom, there aren’t many reliable resources for people who are curious about taking it. That could change in the near future, though. In December a laboratory at the University of Florida received a $3.5 million grant from the National Institute on Drug Abuse to research kratom.
					</p>
					<p>
						Lance McMahon, Ph.D., a professor of pharmacodynamics at UF’s College of Pharmacy who was also involved with the new study cited in this article, told Inverse that he expected that his lab’s research could lead to human trials within five years.
					</p>
					<p
						class="text-left my-9"
						style="font-size: 14px"
					>
						Source <a
							href="https://www.inverse.com/article/56104-what-are-the-risks-and-benefits-of-kratom/amp"
							target="blank"
						>https://www.inverse.com/article/56104-what-are-the-risks-and-benefits-of-kratom/amp</a>
					</p>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import menuBar from './extended/menu.vue'

export default {
	components: {
		menuBar
	}
}
</script>
