<template>
	<div class="blog">
		<menuBar />
		<v-row class="mt-5 pa-10">
			<v-col
				cols="12"
				xl="6"
				lg="6"
				md="6"
				sm="12"
				xs="12"
				class="pa-5"
			>
				<v-row
					style="cursor: pointer"
					@click="$router.push('/blog/1/Hi-There-!')"
				>
					<v-col cols="4">
						<v-img :src="$root.publicPath + `image/blog1.jpg`" />
					</v-col>
					<v-col>
						<h3 class="font-weight-light orange--text mb-2">
							Hi There !
						</h3>
						<div class="font-weight-light title mb-2 text-warp text-justify text_content">
							<p>
								<b>What Are the Benefits (and Risks) of Kratom? Scientists Have Some Answers</b>
							</p>
							<p>
								Over the past few years, kratom exploded into public consciousness in the United States. The herbal drug, which is legal in most of the country, has attracted a solid mix of positive and negative attention. People with chronic pain, opioid use disorder, and other health issues have sought out kratom as a natural alternative to prescription opioids — or illicit fentanyl and heroin. Meanwhile, federal regulators have tried to restrict access to the substance. The Food and Drug Administration has argued that another opioid on the market, especially a poorly understood one that’s available to purchase without a prescription, could contribute to the ongoing opioid overdose crisis that claims tens of thousands of lives each year.
							</p>
						</div>
						<div class="mt-5">
							<v-spacer></v-spacer>
							<v-btn
								text
								class="float-right"
							>
								Read more...
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-col>
			<v-col
				cols="12"
				xl="6"
				lg="6"
				md="6"
				sm="12"
				xs="12"
				class="pa-5"
			>
				<v-row
					style="cursor: pointer"
					@click="$router.push('/blog/2/Kratom-Benefit')"
				>
					<v-col cols="4">
						<v-img :src="$root.publicPath + `image/blog2.jpg`" />
					</v-col>
					<v-col>
						<h3 class="font-weight-light orange--text mb-2">
							Kratom Benefit
						</h3>
						<div class="font-weight-light title mb-2 text-warp text-justify text_content">
							<p>
								<b>SEVEN HIDDEN HEALTH BENEFITS OF KRATOM</b>
							</p>
							<p>
								Modern life is hectic, and people have a lot to accomplish in a single day. A large number of people compromise their health to perform their daily tasks. They do not get enough time to incorporate necessary doctor visits in their routine and tend to treat their minor illnesses on their own.
							</p>
						</div>
						<div class="mt-5">
							<v-spacer></v-spacer>
							<v-btn
								text
								class="float-right"
							>
								Read more...
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import menuBar from './extended/menu.vue'

export default {
	components: {
		menuBar
	}
}
</script>

<style scoped>
.text_content {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 6;
	-webkit-box-orient: vertical;
}

@media only screen and (max-width: 750px) {
	.title {
		font-size: 13px !important;
		text-align: left !important;
		line-height: 18px;
	}
}
</style>
